declare global {
    interface Window {
        initializeRemote: (remoteKey: string) => Promise<void>;
    }
}
 
type Environment = {
    build_env: string;
    fed_env: string;
};
 
window.initializeRemote = async (remoteKey: string) => {
    const { getRemote, getFederatedEnv } = await import(
        '@innovyze/lib_get_remote'
    );
    const getEnvironment = async (): Promise<Environment> => {
        const file = await fetch('/environment.json'); // CloudOS automatically adds this file to your deployed artifact and contains the env variables defined for each environment. These variables are defined in .cloudos/service_config
        return await file.json();
    };
    const environment = await getEnvironment(); // The environment contains build_env and fed_env
    // @ts-expect-error wrong type
    const federatedEnv = getFederatedEnv(environment);
    // @ts-expect-error unknown remoteKey type
    const remote = getRemote(remoteKey, federatedEnv);
 
    const [remoteReference] = Object.values(remote);
    const [key, url] = remoteReference.split('@');
 
    return new Promise(resolve => {
        const script = document.createElement('script');
 
        script.src = url;
        script.async = true;
        script.onload = () => {
            // @ts-expect-error unknown key type
            resolve(window[key]);
        };
        document.head.appendChild(script);
    });
};
 
import('./index');